<template>
  <merchant-detail :is-edit="true"></merchant-detail>
</template>
<script>
import MerchantDetail from './MerchantDetail.vue';

export default {
  name: 'addMerchant',
  components: { MerchantDetail },
};
</script>
<style>
</style>
